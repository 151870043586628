<template>
	<div>
		<div class="flex justify-between">
			<div class="flex tongji">
				<div>店铺营业额：{{data.turnover}}</div>
				<div class="sx"></div>
				<div>已结算：{{data.settled}}(商家：{{data.merchantSettled}},小程序：{{data.miniProgramSettled}})</div>
				<!-- <div class="sx"></div>
				<div>待结算：{{data.unsettled}}(商家：{{data.merchantUnsettled}},小程序：{{data.miniProgramUnsettled}})</div> -->
			</div>
			<el-form :inline="true" :model="searchForm">
				<el-form-item>
					<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="submitSearch()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="order_no" label="订单编号" width="280">
			</el-table-column>
			<el-table-column prop="order_origin" label="订单来源" width="180">
				<template slot-scope="scope">
					<div v-if="scope.row.order_origin == 1"> 小程序 </div>
					<div v-if="scope.row.order_origin == 2"> 线下门店 </div>
					<div v-if="scope.row.order_origin == 3"> 自营H5商城 </div>
				</template>
			</el-table-column>
			<el-table-column prop="amount_fee" label="实付金额" width="180">
			</el-table-column>
			<el-table-column prop="pay_method" label="支付方式" width="180">
				<template slot-scope="scope">
					<!-- 1:微信 2 ：支付宝3：会员余额支付 4：现金支付 -->
					<!-- 1:微信   2 ：支付宝 3：会员余额支付 4：现金支付 5 次卡支付 6:套餐支付 -->
					<div class="text-blue" v-if="scope.row.pay_method == 1">微信</div>
					<div class="text-green" v-if="scope.row.pay_method == 2">支付宝</div>
					<div class="text-orange" v-if="scope.row.pay_method == 3">会员余额</div>
					<div class="text-grey" v-if="scope.row.pay_method == 4">现金</div>
					<div class="text-pink" v-if="scope.row.pay_method == 5">次卡支付</div>
					<div class="text-purple" v-if="scope.row.pay_method == 6">套餐支付</div>
				</template>
			</el-table-column>
			<el-table-column prop="pay_time" label="付款时间">
			</el-table-column>
			<el-table-column prop="status" label="订单状态">
				<template slot-scope="scope">
					<!-- 订单状态 订单支付状态,0未付款 1 已付款  2退款中  3 已退款  4退款失败  5已完成 -->
					<div class="text-blue" v-if="scope.row.order_status == 0">未付款</div>
					<div class="text-green" v-if="scope.row.order_status == 1">已付款</div>
					<div class="text-orange" v-if="scope.row.order_status == 2">退款中</div>
					<div class="text-grey" v-if="scope.row.order_status == 3">已退款</div>
					<div class="text-orange" v-if="scope.row.order_status == 4">退款失败</div>
					<div class="text-grey" v-if="scope.row.order_status == 5">已完成</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				data: {},
				tableData: [], // 数据
				total: 0,
				searchForm: {
					page: 1,
					page_size: 10,
					start_date: '',
					end_date: ''
				},

			};
		},
		computed: {
			daterange: {
				get() {
					return [this.searchForm.start_date, this.searchForm.end_date]
				},
				set(val) {
					if (val) {
						this.searchForm.start_date = val[0] + ' 00:00:00'
						this.searchForm.end_date = val[1] + ' 23:59:59'
					} else {
						this.searchForm.start_date = ''
						this.searchForm.end_date = ''
					}
				}
			}
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			submitSearch(){
				this.searchForm.page = 1;
				this.search()
			},
			search() {
				this.loading = true;
				this.$api.store.getWalletOrderRecord(this.searchForm).then(res => {
					this.total = res.data.count;
					this.data = res.data;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},

		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}

	.sx {
		height: 18px;
		width: 1px;
		background-color: #ccc;
		margin: 0 10px;
	}

	.tongji {
		height: 40px;
		align-items: center;
	}
</style>
