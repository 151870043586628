<template>
    <div class="wrap">
        <div class="tit">{{vuex_user.merchant_name}}</div>
        <div class="balance">可用余额：<span class="text-lg text-orange">{{data.balance}}</span>元</div>
        <el-button type="primary" @click="$router.push('/account/withdrawal')" v-if="!isSelf">提现</el-button>
        <div class="flex margin-top">
            <div class="flex-sub text-left border">
                <div class="text-sm text-grey margin-bottom-sm">营业额</div>
                <div class="text-lg text-orange">{{data.turnover}}</div>
            </div>
            <div class="flex-sub text-left border">
                <div class="text-sm text-grey margin-bottom-sm">已结算</div>
                <div class="text-lg text-orange">{{data.settled}}</div>
            </div>
            <div class="flex-sub text-left border">
                <div class="text-sm text-grey margin-bottom-sm">待结算</div>
                <div class="text-lg text-orange">{{data.unsettled}}</div>
            </div>
            <div class="flex-sub text-left border">
                <div class="text-sm text-grey margin-bottom-sm">已提现</div>
                <div class="text-lg text-orange">{{data.withdrawn}}</div>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="margin-top">
            <el-tab-pane label="订单记录" name="ddjl">
                <my-order-record></my-order-record>
            </el-tab-pane>
            <el-tab-pane label="提现记录" name="txjl">
                <my-withdrawal-record></my-withdrawal-record>
            </el-tab-pane>
            <el-tab-pane label="账户记录" name="zhjl">
                <my-wallet-log></my-wallet-log>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>


<script>
    // 引入公用表单验证
    import rules from "../../common/common.rules";
    import myOrderRecord from "./children/MyOrderRecord.vue"
    import myWithdrawalRecord from "./children/MyWithdrawalRecord.vue"
    import myWalletLog from "./children/MyWalletLog.vue"
    export default {
        components: {
            myOrderRecord,
            myWithdrawalRecord,
            myWalletLog
        },
        data() {
            return {
                rules,
                activeName: 'ddjl',
                data: {},
                merchant: null
            };
        },
        computed: {
            isSelf() {
                if (this.merchant) {
                    var arr = [40, 41, 42, 50, 69]
                    return arr.indexOf(Number(this.merchant.id)) >= 0;
                }
                return true
            }
        },
        mounted() {
            // 获取统计数据
            this.$api.store.getWalletInfo().then(res => {
                this.data = res.data;
            })
            this.$api.store.getMerchantInfo().then(res => {
                this.merchant = res.data;
            });

        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            }
        }
    };
</script>
<style scoped lang="scss">
    .tit {
        font-size: 30px;
    }

    .balance {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .border {
        border: 1px solid #ccc;
        border-right: none;
        padding: 20px;

        &:last-child {
            border-right: 1px solid #ccc;
        }
    }
</style>
