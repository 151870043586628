<template>
	<div>
		<div class="flex justify-between">
			<div class="flex tongji">
				<div>已提现（元）：{{data.withdrawn_money}}</div>
				<div class="sx"></div>
				<div>提现待审核（元）：{{data.no_audit_money}}</div>
			</div>
			<el-form :inline="true" :model="searchForm">
				<el-form-item>
					<el-date-picker v-model="daterange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="submitSearch()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="order_no" label="提现流水号" width="280">
			</el-table-column>
			<el-table-column prop="type" label="提现银行" width="180">
				<template slot-scope="scope">
					<div v-if="scope.row.type == 1">微信</div>
					<div v-if="scope.row.type == 2">支付宝</div>
					<div v-if="scope.row.type == 3">{{scope.row.subbranch}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="account_number" label="提现账户" width="180">
                <template slot-scope="{row}">
                    <div v-if="row.user_id">{{row.real_name}}</div>
                    <div v-else>{{row.account_number}}</div>
                </template>
			</el-table-column>
			<el-table-column prop="account_name" label="账户姓名">
                <template slot-scope="{row}">
                    <div v-if="row.user_id">{{row.real_name}}</div>
                </template>
			</el-table-column>
			<el-table-column prop="phone" label="手机号码">
			</el-table-column>
			<el-table-column prop="money" label="金额">
			</el-table-column>
			<el-table-column prop="pay_money" label="实际到账金额（扣除手续费0.6%）">
			</el-table-column>
			<el-table-column prop="created_at" label="提现日期">
			</el-table-column>
			<el-table-column prop="payment_time" label="到账日期">
                <template slot-scope="{row}">
                    <div v-if="row.payment_time">{{row.payment_time}}</div>
                    <div v-else>{{row.created_at}}</div>
                </template>
			</el-table-column>
			<el-table-column prop="audit_status" label="状态">
				<template slot-scope="scope">
					<div class="text-center text-orange" v-if="scope.row.audit_status == 0">审核中</div>
					<div class="text-center text-green" v-if="scope.row.audit_status == 1">已打款</div>
				</template>
			</el-table-column>
            <el-table-column prop="remark" label="备注">
            </el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				data: {},
				tableData: [], // 数据
				total: 0,
				searchForm: {
					page: 1,
					page_size: 10,
					start_date: '',
					end_date: ''
				},

			};
		},
		computed: {
			daterange: {
				get() {
					return [this.searchForm.start_date, this.searchForm.end_date]
				},
				set(val) {
					if (val) {
						this.searchForm.start_date = val[0] + ' 00:00:00'
						this.searchForm.end_date = val[1] + ' 23:59:59'
					} else {
						this.searchForm.start_date = ''
						this.searchForm.end_date = ''
					}
				}
			}
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			submitSearch(){
				this.searchForm.page = 1;
				this.search()
			},
			search() {
				this.loading = true;
				this.$api.store.getWithdrawalRecord(this.searchForm).then(res => {
					this.total = res.data.count;
					this.data = res.data;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},

		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}

	.sx {
		height: 18px;
		width: 1px;
		background-color: #ccc;
		margin: 0 10px;
	}

	.tongji {
		height: 40px;
		align-items: center;
	}
</style>
